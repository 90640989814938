import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';

import './MainMenu.css';

export default function MainMenu() {
  const [t] = useTranslation();
  const navigate = useNavigate();
  return (
    <Container fluid='sm'>
      <Row>
        <Col className="d-flex p-2 flex-row justify-content-center">
          <div className='main-menu-logo'/>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button onClick={() => navigate('/new')} className='w-100'>{t('tournament.new')}</Button>
        </Col>
      </Row>
      <br/>
      <Row>
        <Col>
          <Button variant='success' onClick={() => navigate('/join')} className='w-100'>{t('tournament.join')}</Button>
        </Col>
      </Row>
    </Container>
  )
}
