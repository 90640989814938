import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';

const SCORE_LABELS = ['M', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 'X'];
const SCORE_VALUES = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
const ENABLE_SCORE = {
  custom: [true, true, true, true, true, true, true, true, true, true, true, true],
  outdoor: [true, true, true, true, true, true, true, true, true, true, true, true],
  indoor: [true, true, true, true, true, true, true, true, true, true, true, false],
}
const SHOW_SCORE = {
  custom: [true, true, true, true, true, true, true, true, true, true, true, true],
  outdoor: [true, true, true, true, true, true, true, true, true, true, true, true],
  indoor: [true, true, true, true, true, true, true, true, true, true, true, false],
}
// const SCORE_COLORS = ['white', 'white', 'white', 'black', 'black', 'blue', 'blue', 'red', 'red', 'yellow', 'yellow', 'yellow'];
const SCORE_COLORS = [
  'outline-secondary',
  'outline-secondary',
  'outline-secondary',
  'outline-dark',
  'outline-dark',
  'outline-primary',
  'outline-primary',
  'outline-danger',
  'outline-danger',
  'outline-warning',
  'outline-warning',
  'outline-warning'
];

export default function Arrow(props) {
  const handleChange = (e) => props.onChange(
    {
      id: props.number,
      score: parseInt(e.target.value),
    }
  );

  return (
    <ToggleButtonGroup
        type='radio'
        name={`arrow-score-${props.roundId}-${props.number}`}
        defaultValue={props.defaultValue}
      >
      {
        SCORE_LABELS.map((label, index) => {
          return ( SHOW_SCORE[props.tournament?.type][index] &&
            <ToggleButton
                key={`arrow-${props.roundId}-${props.number}-${label}`}
                id={`arrow-${props.roundId}-${props.number}-${label}`}
                value={SCORE_VALUES[index]}
                onChange={handleChange}
                size="sm"
                variant={SCORE_COLORS[index]}
                disabled={props.disabled || !ENABLE_SCORE[props.tournament?.type][index] }
              >
              {label}
            </ToggleButton>
          );
        })
      }
    </ToggleButtonGroup>
    
  );
}