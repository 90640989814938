import {useState} from 'react';
import Button from 'react-bootstrap/Button';

import Avatar from './Avatar';
import './Avatar.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const SHOW_AVATAR = 16;

export default function AvatarList(props) {
  const [active, setActive] = useState(props.defaultValue);
  const handleChange = (value) => {
    setActive((prevState) => value);
    props?.onChange(parseInt(value));
  };

  return (
    <>
        <Row xs={4}>
        {
          Array(SHOW_AVATAR).fill().map((label, index) => {
            return (
              <Col key={`avatar-col-${props?.name}-${index}`}>
                <Button
                    variant='white'
                    key={`avatar-${props?.name}-${index}`}
                    id={`avatar-${props?.name}-${index}`}
                    value={index}
                    onClick={() => handleChange(index)}
                    active={index === active}
                  >
                  <Avatar id={index}></Avatar>
                </Button>
              </Col>
            );
          })
        }
        </Row>
    </>
  );
}
