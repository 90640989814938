import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Accordion from 'react-bootstrap/Accordion';
import Alert from 'react-bootstrap/Alert';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import {BsPlusSquare, BsArrowClockwise, BsFillShareFill} from 'react-icons/bs';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';

import Round from "./Round";
import PlayerInfo from './PlayerInfo';
import PlayersList from './PlayersList';
import Scoreboard from './Scoreboard';
import TournamentInfo from './TournamentInfo';

export default function Game(props) {
  const [t] = useTranslation();
  const invitationLink = `${process.env.REACT_APP_INVITATION_URL}/#/join/${props.tournament.id}`;
  const wpInvitationLink = encodeURIComponent(`${props.player.name} ${t('invitation.share_message.sent_you')} ${props.tournament.name}. ${t('invitation.share_message.click_link')} ${invitationLink}`);
  const [showShare, setShowShare] = useState(true);
  const [tab, setTab] = useState('tournament')

  const handlerTabs = (_tab) => {
    setTab(p => _tab);
    if (_tab === 'rounds') {
      props.refreshRound();
    } else {
      props.refresh();
    }
  }

  useEffect(() => {
    if(props.tournament.status !== undefined && props.tournament.status !== 1) {
      setShowShare(p => false);
    }
  }, [props.tournament]);

  return (
    <>
      <PlayerInfo {...props}/>
      <br />
      <Container fluid='sm'>
        <Row>
          <Col>
            {
              showShare &&
                <Alert dismissible onClose={() => setShowShare(false)}>
                  <p style={{overflowWrap: 'break-word'}}>{t('invitation.link')}: {invitationLink}</p>
                  <div>
                    <a className='btn btn-primary' href={`whatsapp://send?text=${wpInvitationLink}`} data-action="share/whatsapp/share" target="_blank"><BsFillShareFill /></a>
                  </div>
                </Alert>
            }
            <Tabs
              defaultActiveKey={tab}
              activeKey={tab}
              onSelect={handlerTabs}
              style={{marginBottom: '5px'}}
            >
              <Tab eventKey='tournament'
                title={t('tabs.tournament')}
                onClick={props.refresh}
              >
                <TournamentInfo
                  tournament={props.tournament}
                  updateTournament={props.updateTournament}
                  isOwner={props.player.isOwner}
                  closeSession={props.closeSession}
                  changeTab={handlerTabs}
                />
              </Tab>
              <Tab eventKey="players" title={t('tabs.players')}>
                <PlayersList players={props.players} onRefresh={props.refresh} isRefreshing={props.isRefreshing}/>
              </Tab>
              <Tab eventKey="rounds" title={t('tabs.rounds')}>
                <Nav className="justify-content-end">
                  <Nav.Item>
                    {props.player.isOwner && (
                      <Button style={{marginRight: '5px'}} variant="primary" type="button" onClick={props.createRound} size='small' disabled={props.tournament.status !== 2 || props.isRefreshing}>
                          <BsPlusSquare />
                      </Button>
                    )}
                    <Button variant="secondary" type="button" onClick={props.refreshRound} size='small'>
                      {props.isRefreshing && <div><Spinner animation="border" size='sm'/></div>}
                      {!props.isRefreshing && <BsArrowClockwise></BsArrowClockwise>}
                    </Button>
                  </Nav.Item>
                </Nav>
                <Accordion defaultActiveKey={props?.rounds.length} style={{marginTop: '5px'}}>
                {
                  props.rounds.map((round)=>
                  <Round
                    key={`round-${round.id}`}
                    round={round}
                    tournament={props.tournament}
                    player={props.player}
                    onSubmit={() => props.onSumbit(props.tournament.id)}/>)
                }
                </Accordion>
              </Tab>
              <Tab eventKey="scoreboard" title={t('tabs.score')}>
                <Scoreboard
                  players={props.players}
                  onRefresh={props.refresh}
                  isRefreshing={props.isRefreshing}
                  tournament={props.tournament}
                  rounds={props?.rounds}
                />
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
    </>
  );
}
