import {useEffect, useState} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import {
  Route,
  Routes,
} from "react-router-dom";


import MainMenu from './components/MainMenu';
import JoinTournament from './components/JoinTournament';
import NewTournament from './components/NewTournament';
import Game from './components/Game';
import {ScoreboardView} from './components/Scoreboard';

import {apiGet, apiPost, apiPut, apiDelete } from './helpers/utils';

import './App.css';
import { LanguageLinks } from './components/Languages';

function App() {

  const [isOwner, setIsOwner] = useState(false);
  const [tournament, setTournament] = useState({});
  const [player, setPlayer] = useState({});
  const [rounds, setRounds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sessionActive, setSessionActive] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const _checkSession = () => {
    return apiGet('/sessions')
    .then((response)=>{
      if(response.ok && response.redirected === false) {
        return response.json();
      }
      return Promise.resolve(null);
    })
  };

  const checkSession = () => {
    _checkSession()
    .then((session) => {
      if (session === null) {
        if (!location.pathname.includes('join') && !location.pathname.includes('scoreboard')) {
          navigate('/');
        }
        return;
      }
      setSessionActive(p => true);
      const tournaments =_refreshTournament(session.tournament_id)
      const rounds = _refreshRounds(session.tournament_id);
      Promise.all([tournaments, rounds])
      .then(([tournamentsValue, roundsValue]) => {
        const players = tournamentsValue.players_list.filter((player) => player.id === session.player_id);
        roundsValue.sort((a, b) => b.id - a.id );
        setPlayer(prevState=> players[0]);
        setTournament(prevState => tournamentsValue);
        setRounds(prevState => roundsValue);
        navigate('/tournament');
      });
    });
  };

  const _closeSession = () => {
    return apiDelete('/sessions')
    .then(response => response.json());
  };

  const closeSession = () => {
    _closeSession()
    .then(() => {
      setSessionActive(p => false);
      navigate('/');
    });
  };

  const _refreshTournament = (tournamentId) => {
    setIsLoading(p => true);
    return apiGet(`/${tournamentId}`)
    .then((response) => response.json())
    .finally(() => setIsLoading(p => false));
  }

  const refreshTournament = () => {
    return _refreshTournament(tournament.id)
    .then(setTournament);
  }

  const updateTournament = (status) => {
    const data = {
      status: status,
    };
    return apiPut(`/${tournament.id}`, data)
    .then((response) => response.json());
  }

  const newRound = (e) => {
    e.preventDefault();
    apiPost(`/${tournament.id}/rounds`)
    .then((response) => response.json())
    .then(refreshRounds);
  };

  const _refreshRounds = (tournamentId) => {
    setIsLoading(p => true);
    return apiGet(`/${tournamentId}/rounds`)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      return Promise.resolve({});
    })
    .catch(console.log)
    .finally(() => setIsLoading(p => false));
  };

  const refreshRounds = () => {
    _refreshRounds(tournament.id)
    .then((rounds) => {
      rounds.sort((a, b) => b.id - a.id );
      setRounds(prevState => rounds);
    });
  };

  const _refreshCurrentPlayer = (tournamentId, playerId) => {
    return apiGet(`/${tournamentId}/players/${playerId}`)
    .then(response => response.json())
  };

  const refreshCurrentPlayer = () => {
    _refreshCurrentPlayer(tournament.id, player.id)
    .then(setPlayer);
  };

  const onCreate = (tournament) => {
    setTournament(tournament);
    setIsOwner(prevState => true);
  };

  const onJoin = (data) => {
    setPlayer(prevState => {
      data.player['isOwner'] = isOwner;
      return data.player
    });
    _refreshTournament(data.tournament.id)
    .then(tournament => {
      setTournament(prevState => tournament);
      setSessionActive(p => true);
      navigate('/tournament');
    })
  };

  useEffect(() => {
    checkSession();
  // eslint-disable-next-line
  }, [])

  return (
      <>
      <Routes>
        <Route path='/' element={<MainMenu />} />
        <Route path='/new' element={
          <NewTournament
            onCreate={onCreate}
            sessionActive={sessionActive}
          />}
        />
        <Route path='/join' element={
          <JoinTournament
            onJoin={onJoin}
            isOwner={isOwner}
            sessionActive={sessionActive}
          />}
        />
        <Route path='/join/:tournamentId' element={
          <JoinTournament
            onJoin={onJoin}
            isOwner={isOwner}
            sessionActive={sessionActive}
          />}
        />
        <Route path='/tournament' element={
          <Game
            closeSession={closeSession}
            tournament={tournament}
            rounds={rounds}
            refreshRound={refreshRounds}
            refresh={refreshTournament}
            createRound={newRound}
            player={player}
            players={tournament.players_list}
            updateTournament={updateTournament}
            isRefreshing={isLoading}
            onSumbit={() => {
              refreshRounds();
              refreshCurrentPlayer();
            }}
          />
        }
        />
        <Route path='/scoreboard/:tournamentId' element={
          <ScoreboardView
            onRefresh={(tournamenId) => {
                const tournament = _refreshTournament(tournamenId);
                const rounds = _refreshRounds(tournamenId);
                return Promise.all([tournament, rounds]);
              }
            }
            isRefreshing={isLoading}
          />}
        />
      </Routes>
      <LanguageLinks />
      <div className='d-flex justify-content-center'>&#169; 2022</div>
      </>
  );
}

export default App;
