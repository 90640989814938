import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import { useTranslation } from 'react-i18next';

const STATUS = ['', 'CREATED', 'STARTED', 'FINISHED'];

export default function TournamentInfo(props) {

  const [t] = useTranslation();
  const handlerClick = () => {
    const response = window.confirm('Are you sure to leave the session?');
    if(response) {
      props.closeSession();
    }
  }

  return (
    <>
    <Card>
      {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
      <Card.Body>
        <Card.Title>{props.tournament.name}</Card.Title>
        <Card.Text>
          <ListGroup>
            <ListGroup.Item>{t('tournament.arrows')}: {props.tournament.arrows}</ListGroup.Item>
            <ListGroup.Item>{t('tournament.players')}: {props.tournament.players_list?.length}</ListGroup.Item>
            <ListGroup.Item>{t('tournament.status')}: {STATUS[props.tournament.status]}</ListGroup.Item>
            <ListGroup.Item>{t('tournament.type')}: {props.tournament.type} </ListGroup.Item>
            <ListGroup.Item>{t('invitation.id')}: {props.tournament.id} </ListGroup.Item>
          </ListGroup>
        </Card.Text>
        <ButtonGroup className='w-100'>
        { props.isOwner && (
            <>
            <Button
              variant="success" 
              type="button"
              onClick={()=> {
                props.updateTournament(2).then((response) => {
                  props.changeTab('rounds');
                });
              }}
              // disabled={props.tournament.status !== 1 || props.tournament.players !== props.tournament.players_list.length}
              disabled={props.tournament.status !== 1}
              size="small">
                {t('tournament.start')}
            </Button>
            <Button 
              variant="danger"
              type="button"
              onClick={()=> props.updateTournament(3)}
              disabled={props.tournament.status !== 2}
              size="small">
                {t('tournament.finish')}
            </Button>
            </>
        )}
            <Button
              variant="dark"
              type="button"
              onClick={handlerClick}
              size="small">
                {t('close_session')}
            </Button>
          </ButtonGroup>
      </Card.Body>
    </Card>
    </>
  );
}