import { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Badge from 'react-bootstrap/Badge';

import Avatar from './Avatar';

export default function PlayerInfo(props) {

  const [score, setScore] = useState([0, 0, 0]);

  const getScore = (type) => {
    const total = props.player.score?.total;
    const X = props.player.score?.X;
    const _10 = ('10' in props.player?.score)?props.player?.score['10']: 0;
    const _9 = ('9' in props.player?.score)?props.player?.score['9']: 0;
    if (type == 'outdoor') {
      return [total, X, _10];
    }
    return [total, _10, _9];
  };

  useEffect(() => {
    if ('score' in props.player) {
      setScore(p => getScore(props.tournament.type));
    }
  }, [props.player]);

  return (
    <Navbar bg="dark" variant="dark">
      <Container>
        <Navbar.Brand>
          <div className="d-inline-block align-middle me-1">
            <Avatar id={props.player.avatar_id}/>
          </div>
          {props.player.name}
        </Navbar.Brand>
        <Nav>
          <Badge pill bg='success' className='m-1'>{score[0]}</Badge>
          <Badge pill bg='warning' className='m-1'>{score[1]}</Badge>
          <Badge pill bg='secondary' className='m-1'>{score[2]}</Badge>
        </Nav>
      </Container>
    </Navbar>
  );
}