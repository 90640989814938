import { useState } from 'react';
import { useParams } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Button from 'react-bootstrap/Button';
import Navbar from 'react-bootstrap/Navbar';
import InputGroup from 'react-bootstrap/InputGroup';
import Container from 'react-bootstrap/Container';
import { getRandomInt } from '../helpers/utils';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import Spinner from 'react-bootstrap/Spinner';
import { useForm } from "react-hook-form";
import { useNavigate} from 'react-router-dom';

import {apiPost} from '../helpers/utils';

import Avatar from './Avatar';
import AvatarList from './AvatarList';
import { BackToTournament } from './BackToTournament';

const iconIndex = getRandomInt(16);

const invitationStyle = {
  fontSize: '55px',
  textAlign: 'center',
  paddingTop: 0,
  paddingBottom: 0,
  color: 'blue'
}

export default function JoinTournament(props) {
  const [t] = useTranslation();
  const {tournamentId} = useParams();
  const [avatarId, setAvatarId] = useState(0);
  const [joining, setJoining] = useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm();
  const navigate = useNavigate();
  const submitHandler = (form) => {
    setJoining(prevStatus => true);
    const invitationId = form.invitationId.toLowerCase();
    const data = {
      avatar_id: avatarId,
      name: form.player_name,
      isOwner: props.isOwner,
    };

    apiPost(`/${invitationId}/players`, data)
    .finally(() => setJoining(prevStatus => false))
    .then((response) => {
      if (!response.ok)
        return Promise.resolve(null);
      return response.json();
    })
    .then(player => {
      if(player !== null) {
        props.onJoin({
          tournament: {
            id: invitationId,
          },
          player: player, 
        });
      }
    });
  }

  return (
    <>
      <Navbar bg="dark" variant="dark">
        <Container>
          <Navbar.Brand>
            <div className="d-inline-block align-middle">
              <Avatar id={iconIndex}/>
            </div>
            {' '}
            {t('tournament.join')}
          </Navbar.Brand>
        </Container>
      </Navbar>
      {props.sessionActive && (<BackToTournament />)}
        {!props.sessionActive && (
        <Container fluid='sm'>
        <Row>
            <Col sm={12}>
              <br/>
              <Form onSubmit={handleSubmit(submitHandler)}>
                <Form.Group >
                  <FloatingLabel label={t('invitation.id')}>
                  {/* <Form.Label>{t('invitation.id')}</Form.Label> */}
                  <Form.Control
                    type='text'
                    name='invitationId'
                    defaultValue={tournamentId}
                    readOnly={tournamentId!==undefined}
                    isInvalid={errors.invitationId}
                    maxLength={4}
                    size='lg'
                    style={invitationStyle}
                    {...register('invitationId',{ required: true })}
                  />
                  </FloatingLabel>
                  <Form.Control.Feedback type="invalid">
                    {t('errors.tournament_invitation')}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group >
                  <Form.Label>{t('player.name')}</Form.Label>
                  <InputGroup>
                    <InputGroup.Text ><Avatar size='sm' id={avatarId}></Avatar></InputGroup.Text>
                    <Form.Control
                      type="text"
                      placeholder={t('player.name')}
                      name='player_name'
                      isInvalid={errors.player_name}
                      {...register('player_name',{ required: true })}
                    />
                    <Form.Control.Feedback type="invalid">
                      {t('errors.tournament_field_name')}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <br/>
                <div className='w-100'>
                  <span>{t('player.chose_avatar')}</span>
                  <AvatarList onChange={setAvatarId} defaultValue={0}/>
                </div>
                <br/>
                <div className='d-flex p-2 flex-row justify-content-center'>
                <Button variant="primary" type="submit" className='w-100' disabled={joining}>
                  {joining && (<><Spinner animation="border" size='sm'/>{' '}</>)}
                  {t('tournament.join_to')}
                </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
      )}
    </>
  )
}
