import { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';

export default function LanguageSelector() {
  const [t, i18n] = useTranslation();
  return (
    <Form.Group >
      <Form.Label>{t('languages.title')}</Form.Label>
      <Form.Select size="sm" 
        onChange={(e)=>{i18n.changeLanguage(e.target.value)}}
        defaultValue={i18n.language}
      >
        <option value='en-US'>{t('languages.en')}</option>
        <option value='es-AR'>{t('languages.es')}</option>
      </Form.Select>
    </Form.Group>
  )
}

export function LanguageLinks() {
  const [t, i18n] = useTranslation();
  const [active, setActive] = useState(i18n.language);
  const handlerClick = (value) => setActive(prevState => value);
  useEffect(() => {
    i18n.changeLanguage(active);
  }, [active]);
  return (
    <>
      <br/>
      <div className='d-flex justify-content-center'>
        <a className='link-secondary' onClick={() => handlerClick('en-US')}>{t('languages.en')}</a>
        &nbsp;|&nbsp;
        <a className='link-secondary' onClick={() => handlerClick('es-AR')}>{t('languages.es')}</a>
      </div>
    </>
  );
}