import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Nav from 'react-bootstrap/Nav';
import { BsArrowClockwise, BsFillPersonCheckFill, BsFillShareFill } from "react-icons/bs";
import { useTranslation } from 'react-i18next';
import Spinner from 'react-bootstrap/Spinner';
import Badge from 'react-bootstrap/Badge';

import Avatar from './Avatar';
import { getRandomInt } from '../helpers/utils';

const iconIndex = getRandomInt(16);

export function ScoreboardView(props) {
  const [t] = useTranslation();
  const TOURNAMENT_STATUS = [
    {label: '', badge: 'warning'},
    {label: t('tournament.states.created'), badge: 'warning'},
    {label: t('tournament.states.started'), badge: 'success'},
    {label: t('tournament.states.finished'), badge: 'danger'},
  ];

  const {tournamentId} = useParams();
  const [tournament, setTournament] = useState({});
  const [rounds, setRounds] = useState({});
  const refreshTournament = () => {
    props.onRefresh(tournamentId).then(
      ([tournament, rounds]) => {
        setTournament(p => tournament);
        setRounds(p => rounds);
      }
    );
  }
  useEffect(() => {
    refreshTournament();
  }, [])
  return (
    <>
      <Navbar bg="dark" variant="dark">
        <Container>
          <Navbar.Brand>
            <div className="d-inline-block align-middle">
              <Avatar id={iconIndex}/>
            </div>
            {' '}
            {tournament.name}
          </Navbar.Brand>
        </Container>
      </Navbar>
      <Container fluid='sm'>
      <Row>
          <Col sm={12}>
          <Badge pill bg={TOURNAMENT_STATUS[tournament?.status]?.badge} className='m-1'>{TOURNAMENT_STATUS[tournament.status]?.label}</Badge>
          {tournament?.status !== undefined && tournament?.status !== 1 && rounds.length > 0 && <Badge pill bg='primary' className='m-1'>{t('scoreboard.round')}{': '}{rounds.length}</Badge>}
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Scoreboard
              {...props}
              onRefresh={refreshTournament}
              tournament={tournament}
              rounds={rounds}
              players={tournament.players_list}
            />
          </Col>
        </Row>
      </Container>
    </>)
}

export default function Scoreboard(props) {
  const [t] = useTranslation();
  const invitationLink = `${process.env.REACT_APP_INVITATION_URL}/#/scoreboard/${props.tournament.id}`;
  const wpInvitationLink = encodeURIComponent(`${t('invitation.share_message.sent_you_scoreboard')} ${props.tournament.name}. ${t('invitation.share_message.click_link')} ${invitationLink}`);
  const getTableHead = (type) => {
    if (type === 'indoor') {
      return (
        <>
          <th></th>
          <th>{t('scoreboard.rounds')}</th>
          <th>{t('scoreboard.total')}</th>
          <th>{t('scoreboard.10')}</th>
          <th>{t('scoreboard.9')}</th>
          <th>{t('scoreboard.average')}</th>
          <th>{t('player.name')}</th>
        </>
      )
    }
    return (
      <>
        <th></th>
        <th>{t('scoreboard.rounds')}</th>
        <th>{t('scoreboard.total')}</th>
        <th>{t('scoreboard.X')}</th>
        <th>{t('scoreboard.10_X')}</th>
        <th>{t('scoreboard.average')}</th>
        <th>{t('player.name')}</th>
      </>
    );
  };

  const getScore = (type, player) => {
    if (type === 'indoor') {
      return (
        <>
          <td>{player.score['10']}</td>
          <td>{player.score['9']}</td>
        </>
      )
    }
    return (
      <>
        <td>{player.score['X']}</td>
        <td>{player.score['10'] + player.score['X']}</td>
      </>
    );
  };

  const sortScoreIndoor = (a, b) => {
    return (b.score.total - a.score.total) || (b.score['10'] - a.score['10']) || (b.score['9'] - a.score['9'])
  }

  const sortScoreOutdoor = (a, b) => {
    return (b.score.total - a.score.total) || (b.score['X'] - a.score['X']) || ((b.score['10'] + b.score['X']) - (a.score['10'] + a.score['X']))
  }

  const sortFunction = props.tournament.type === 'indoor'?sortScoreIndoor:sortScoreOutdoor;

  return (
    <>
      <Nav className="justify-content-end">
        <Nav.Item >
          <a style={{marginRight: '5px'}} className='btn btn-primary' href={`whatsapp://send?text=${wpInvitationLink}`} data-action="share/whatsapp/share" target="_blank">
            <BsFillShareFill />
          </a>
          <Button
            size='small'
            variant='secondary'
            onClick={props.onRefresh}
            disabled={props.isRefreshing}
          >
            {props.isRefreshing && <div><Spinner animation="border" size='sm'/></div>}
            {!props.isRefreshing && <BsArrowClockwise></BsArrowClockwise>}
          </Button>
        </Nav.Item>
      </Nav>
      <Table striped bordered hover responsive='sm' size='sm' style={{marginTop: '5px'}}>
        <thead>
          <tr>
            {getTableHead(props.tournament?.type)}
          </tr>
        </thead>
        <tbody>
          {
            props.players?.sort(sortFunction).map((player, index) => {
              let average = 0;
              if (player.score.rounds > 0) {
                average = (player.score.total / (player.score.rounds * props.tournament.arrows)).toFixed(1);
              }
              return (
                <tr key={`row-${index}`}>
                  <td><Avatar size='sm' id={player.avatar_id} /></td>
                  <td>{player.score.rounds}</td>
                  <td>{player.score.total}</td>
                  {getScore(props.tournament?.type, player)}
                  <td>{average}</td>
                  <td className='align-middle'>{player.name}{' '}{player?.isOwner && <BsFillPersonCheckFill/>}</td>
                </tr>
              )
            })
          }
        </tbody>
      </Table>
    </>
  )
}