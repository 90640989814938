import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';


export function BackToTournament() {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const backTournament = (e) => {
    e.preventDefault();
    navigate('/tournament');
  };

  return (
    <>
      <Container fluid='sm'>
        <Row>
          <Col sm={12}>
            <br />
            <Button variant="primary" type="submit" className='w-100' onClick={backTournament}>
              {t('tournament.back')}
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
}