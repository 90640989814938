const API_URL=process.env.REACT_APP_API_URL;
const FETCH_CREDENTIALS=process.env.REACT_APP_FETCH_CREDENTIALS;

export function apiGet(path = '') {
  return fetch(`${API_URL}/tournaments${path}`, {
    method: 'GET',
    credentials: FETCH_CREDENTIALS,
    headers: {
      'Content-Type': 'application/json' 
    },
  });
}

export function apiPost(path = '', data = {}) {
  return fetch(`${API_URL}/tournaments${path}`, {
    method: 'POST',
    credentials: FETCH_CREDENTIALS,
    headers: {
      'Content-Type': 'application/json' 
    },
    body: JSON.stringify(data),
  });
}

export function apiPut(path = '', data = {}) {
  return fetch(`${API_URL}/tournaments${path}`, {
    method: 'PUT',
    credentials: FETCH_CREDENTIALS,
    headers: {
      'Content-Type': 'application/json' 
    },
    body: JSON.stringify(data),
  });
}

export function apiDelete(path = '') {
  return fetch(`${API_URL}/tournaments${path}`, {
    method: 'DELETE',
    credentials: FETCH_CREDENTIALS,
    headers: {
      'Content-Type': 'application/json' 
    },
  });
}

export function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}