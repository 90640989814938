import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Avatar from './Avatar';
import { getRandomInt } from '../helpers/utils';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import Spinner from 'react-bootstrap/Spinner';
import { useForm } from "react-hook-form";

import {apiPost} from '../helpers/utils';
import { BackToTournament } from './BackToTournament';

const iconIndex = getRandomInt(16);

export default function NewTournament(props) {
  const [t] = useTranslation();
  const [creating, setCreating] = useState(false);
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors } } = useForm();

  const submitHandler = (form) => {
    setCreating(p => true);
    console.log(form);
    const data = {
      name: form.name,
      arrows: parseInt(form.arrows),
      type: !form.type?'indoor':'outdoor',
    };
    apiPost('', data)
    .finally(() => setCreating(p => false))
    .then((response) => {
      if (!response.ok)
        return Promise.resolve(null);
      return response.json();
    })
    .then((tournament) => {
      if( tournament !== null ) {
        props.onCreate(tournament);
        navigate(`/join/${tournament.id}`);  
      }
    });
  }

  return (
    <>
      <Navbar bg="dark" variant="dark">
        <Container>
          <Navbar.Brand>
            <div className="d-inline-block align-middle">
              <Avatar id={iconIndex}/>
            </div>
            {' '}
            {t('tournament.new')}
          </Navbar.Brand>
        </Container>
      </Navbar>
      {props.sessionActive && (<BackToTournament />)}
      {!props.sessionActive && (
        <Container fluid='sm'>
          <Row>
            <Col sm={12}>
              <br/>
              <Form onSubmit={handleSubmit(submitHandler)}>
                <Form.Group>
                  <Form.Label>{t('tournament.name')}</Form.Label>
                  <Form.Control
                    type='tournament'
                    placeholder={t('tournament.name')}
                    name='name'
                    isInvalid={errors.name}
                    {...register('name',{ required: true })}
                  />
                  <Form.Control.Feedback type="invalid">
                    {t('errors.tournament_field_name')}
                  </Form.Control.Feedback>
                </Form.Group>
                 <Form.Group>
                  <Form.Label>{t('tournament.arrows')}</Form.Label>
                  <Form.Control 
                    type='number'
                    min='1'
                    max='12'
                    name='arrows'
                    defaultValue={3}
                    isInvalid={errors.arrows}
                    {...register('arrows', { required: true })}
                  />
                  <Form.Control.Feedback type="invalid">
                  {t('errors.tournament_field_arrows')}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <Form.Label>{t('tournament.type')}</Form.Label>
                  <Form.Check 
                    type="switch"
                    id="type"
                    label={t('tournament.types.outdoor')}
                    {...register('type')}
                  />
                </Form.Group>
                <br />
                <div className='d-flex p-2 flex-row justify-content-center'>
                  <Button type="submit" className='w-100' disabled={creating}>
                    {creating && (<><Spinner animation="border" size='sm'/>{' '}</>)}
                    {t('tournament.create')}
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
      )}
    </>
  )
}
