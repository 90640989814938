import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Nav from 'react-bootstrap/Nav';
import { BsArrowClockwise, BsFillPersonCheckFill } from "react-icons/bs";
import { useTranslation } from 'react-i18next';
import Spinner from 'react-bootstrap/Spinner';

import Avatar from './Avatar';

export default function PlayersList(props) {
  const [t] = useTranslation();
  return (
    <>
      <Nav className="justify-content-end">
        <Nav.Item >
          <Button
              size='small'
              variant='secondary'
              onClick={props.onRefresh}
              disabled={props.isRefreshing}
            >
              {props.isRefreshing && <div><Spinner animation="border" size='sm'/></div>}
              {!props.isRefreshing && <BsArrowClockwise></BsArrowClockwise>}
            </Button>
        </Nav.Item>
      </Nav>

      <Table striped bordered hover size='sm' style={{marginTop: '5px'}}>
        <thead>
          <tr>
            <th>{t('player.avatar_title')}</th>
            <th>{t('player.name')}</th>
          </tr>
        </thead>
        <tbody>
          {
            props.players?.map((player, index) => {
              return (
                <tr key={`row-${index}`}>
                  <td><Avatar size='sm' id={player.avatar_id} /></td>
                  <td className='align-middle'>{player.name}{' '}{player?.isOwner && <BsFillPersonCheckFill/>}</td>
                </tr>
              )
            })
          }
        </tbody>
      </Table>
    </>
  )
}