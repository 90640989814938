import { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import { useTranslation } from "react-i18next";
import Badge from 'react-bootstrap/Badge';

import Arrow from "./Arrow";
import {apiPut} from '../helpers/utils';
import './Round.css';

function Icon() {
  return (<div className='round-icon'/>)
}

export default function Round(props) {
  const [t] = useTranslation();
  const [score, setScore] = useState(
    props.round.status === 1 ? 
    Array(props.tournament.arrows).fill(-1) : 
    props.round?.scores[props.player.id]?.score
  );
  const [stats, setStats] = useState(Array(3).fill(0));
  const showPlayerScore = !('scores' in props.round && props.player.id in props.round.scores);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(
    props.round.status === 1 && showPlayerScore
  );

  const updateStats = () => {
    const _10 = score.reduce((sum, arrow) => arrow === 10? sum + 1: sum, 0);
    const _9 = score.reduce((sum, arrow) => arrow === 9? sum + 1: sum, 0);
    const _X = score.reduce((sum, arrow) => arrow === 11? sum + 1: sum, 0);
    const total = score.reduce((sum, number) => {
      if (number == 11) {
        return sum + number - 1;
      }
      if (number > -1) {
        return sum + number;
      }
      return sum;
    }, 0);
    let _score = [total, _10, _9];
    if (props.tournament.type === 'outdoor') {
      _score = [total, _X, _10];
    }
    setStats(p => _score);
  };

  const handlerOnChange = (arrow) => {
    setScore((p) => {
      p[arrow.id] = arrow.score;
      const pristines = score.filter((arrow) => arrow === -1);
      if (pristines.length === 0)
        setDisableSubmit(p => false);
      return [...p];
    });
  };
  const handlerClick = () => {
    const data = {
      player_id: props.player.id,
      score: score,
    };

    apiPut(`/${props.tournament.id}/rounds/${props.round.id}`, data)
    .then(response=>response.json())
    .then((jsonData) => {
      setIsSubmitEnabled(p => false);
      props.onSubmit(jsonData);
    });
    
  };

  useEffect(() => {
    updateStats();
    }, [score]
  );

  return (
    <Accordion.Item eventKey={props.round.id}>
      <Accordion.Header key={`header-${props.round.id}`}>
        <div className='d-flex justify-content-between w-100'>
          <div className='d-flex align-items-center'>
            <Icon />
            <div key={`title-${props.round.id}`} className='ms-1'>{t('round.title')} {props.round.id}</div>
          </div>
          <div className='d-flex align-items-center'>
            <Badge pill bg='success' className='m-1'>{stats[0]}</Badge>
            <Badge pill bg='warning' className='m-1'>{stats[1]}</Badge>
            <Badge pill bg='secondary' className='m-1'>{stats[2]}</Badge>
          </div>
        </div>
      </Accordion.Header>
      <Accordion.Body key={`body-${props.round.id}`}>
        {isSubmitEnabled &&  <Button onClick={handlerClick} variant='success' size="sm" disabled={disableSubmit} className='w-100'>{t('round.submit_score')}</Button>}
        {
          score.map((s, index) => {
            const defaultValue = showPlayerScore ? s : props.round?.scores[props.player.id]?.score[index];
            return (
                <div key={`arrow-container-${props.round.id}-${index}`}>
                  <div key={`arrow-title-${props.round.id}-${index}`}>{t('arrow.title')} {index + 1}</div>
                  <Arrow
                    number={index}
                    roundId={props.round.id}
                    key={`arrow-${props.round.id}-${index}`}
                    onChange={handlerOnChange}
                    defaultValue={defaultValue}
                    disabled={!isSubmitEnabled}
                    tournament={props.tournament}
                  />
                </div>
            );
          })
        }
        <br /><br />
      </Accordion.Body>
    </Accordion.Item>
  )
}